jQuery(document).ready(function($) {
  require('./components/mobile-header.js');
  require('./components/slider.js');
  require('./components/header-scroll.js');
  require('./components/accordion.js');

  loadMoreProjects.init();
});


// Wrap Menu Sub Menu In Inner Container
var parentContainers = document.getElementsByClassName("menu-item-has-children");

for(var i = 0; i < parentContainers.length; i++) {
  var parentContainer = parentContainers[i];
  var subMenu = parentContainer.querySelector(".sub-menu");

  if(subMenu) {
    var innerContainer = document.createElement("div");
    innerContainer.className = "inner-container";

    // Wrap subMenu with innerContainer
    subMenu.parentNode.insertBefore(innerContainer, subMenu);
    innerContainer.appendChild(subMenu);
  }
}


// Popup Slider
jQuery(document).ready(function($) {
  jQuery('.popup-button').click(function() {
    jQuery(this).next().addClass('active');
    jQuery('body, html').css('overflow-y', 'hidden');
  });

  jQuery('.popup-close').click(function() {
    jQuery(this).closest('.popup-slider').removeClass('active');
    jQuery('body, html').css('overflow-y', 'auto');
  });
});


// Load More Projects
var loadMoreProjects = {
	init: function() {
    jQuery('#load-more-projects').click(function(){
      loadMoreProjects.loadMore();
    });
	},

	loadMore: function() {
		var loadContainer = jQuery('#load-projects-container');
		var postCount = jQuery('#load-projects-container .project-thumbnail').length;
		var totalPosts = loadContainer.data('total');

		if(postCount >= totalPosts) {
			return;
		}

		var data = {
      action: 'load_more_projects',
      post_count: postCount,
      post_search: posts.s,
      current_taxonomy: posts.current_taxonomy,
      current_term: posts.current_term,
      current_child_term: posts.current_child_term,
		};

		jQuery.ajax({
			url: posts.ajaxurl,
			type: "POST",
			data: data,
			beforeSend: function () {

				// Request for posts

				jQuery('#load-more-projects').addClass('loading');
			},
			complete: function () {

				// Request for posts received / response is being sent back to us

				jQuery('#load-more-projects').removeClass('loading');
			},
			success: function(data) {

				// We get posts back / successful response

				if(data) {
          loadContainer.append(data);
				}

				postCount = jQuery('#load-projects-container .project-thumbnail').length;

				if(postCount >= totalPosts) {
          jQuery('#load-more-projects').replaceWith('<a class="button no-posts" href="#load-more-projects-anchor">Back to top</a>');
				}

				return;
			}
		});
	}
}