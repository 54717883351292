jQuery(function($) {
  $('.accordion-item').click(function() {
    const accordionContent = $(this).children('.accordion-item--content');
    const accordionContentHeight = accordionContent[0].scrollHeight;
    const accordionContentNone = $('.accordion-item--content');

    $(this).siblings().removeClass('active');
    $(this).toggleClass('active');

    if($(this).hasClass('active')) {
      accordionContentNone.css('max-height', `0px`);
      accordionContent.css('max-height', `${accordionContentHeight}px`);

    } else {
      accordionContent.css('max-height', `0px`);
    }
  })
});