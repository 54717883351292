jQuery(function($) {
  $('#mobile-menu').click(function(){
    $('header').toggleClass('mobile-active');

    if($('header').hasClass('mobile-active')) {
      $('body, html').css('overflow-y', 'hidden');
    } else {
      $('body, html').css('overflow-y', 'auto');
    }
  });

  var itemHasChildren = $('.menu-item-has-children');
  itemHasChildren.append('<span class="expand-child-items"></span>')

  $('.expand-child-items').click(function () {
    $(this).toggleClass('active');
    $(this).parent('.menu-item-has-children').find('.inner-container').first().toggleClass('active');
  });

  var submenuItems = $('.main-logo');
  // Loop through each submenu item
  submenuItems.each(function() {
    // Get the current submenu item
    var submenuItem = $(this);

    submenuItem.prepend('<div class="row menu-main-logo"><img src="../../..//wp-content/themes/truewater/assets/images/main-logo.svg" alt="Logo"/></div>');
  });

  var submenuItemsKubota = $('.kubota-logo');
  // Loop through each submenu item
  submenuItemsKubota.each(function() {
    // Get the current submenu item
    var submenuItemKubota = $(this);

    submenuItemKubota.prepend('<div class="row"><img src="../../..//wp-content/themes/truewater/assets/images/kubota.svg" alt="Logo"/></div>');
  });
});