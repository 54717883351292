jQuery(function($) {
  $('.slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: slider.hasClass('has-dots'),
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      contain: false,
      selectedAttraction: 0.01,
      friction: 0.15,
    }

    slider.flickity(config)
  })

  $('.auto-slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: false,
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      contain: false,
      autoPlay: true,
      autoPlay: 3000
    }

    slider.flickity(config)
  })

  $('.projects-slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: slider.hasClass('has-dots'),
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: false,
      imagesLoaded: true,
      contain: false,
      selectedAttraction: 0.01,
      friction: 0.15,
      cellAlign: 'left',
    }

    slider.flickity(config)
  })

  $('.product-slider').each(function() {
    var slider = $(this)
    var config = {
      prevNextButtons: false,
      wrapAround: true,
      imagesLoaded: true,
      pageDots: false,
      contain: false,
      selectedAttraction: 0.01,
      friction: 0.15,
    }

    slider.flickity(config)
  })

  $('.carousel-main').flickity();
  $('.carousel-nav').flickity({
    asNavFor: '.carousel-main',
    wrapAround: false,
    imagesLoaded: true,
    pageDots: false,
    prevNextButtons: false,
    cellAlign: 'left',
    contain: true,
  })
})